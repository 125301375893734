import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'snapchat_ad',
    title: 'Snapchat ad',
    channel: 'social',
    platform: 'snapchat',
    outputAction: 'all',
    description: 'Create an ad for a Snapchat ad',
    addBrickText: 'Add ad',
    availableParentBricks: ['snapchat_adsquad'],
    availableChildBricks: [],
    defaultTab: 'adSetup',
    config: {
        general: {
            creativeSelector: {
                multiple: true,
                selectors: ['upload', 'template', 'campaignAssets', 'media'],
                fileType: ['image', 'video'],
                templateTypes: ['dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicImage', 'dynamicVideo', 'dynamicAfterEffects']
            },
            publish: {
                previewType: 'social'
            }
        },
        adSetup: {
            hasAdPlacements: false,
            hideTypeSelector: true
        }
    },
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'adSetup',
            title: 'Ad setup',
            type: 'component',
            component: 'adSetup'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
