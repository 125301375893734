import { MODEL_SETTINGS } from 'components/bricks/constants';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Ad Details',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'ad_account_id',
                identifier: 'snapchat_ad-ad_account_id',
                label: 'Ad account',
                metadataType: 'snapchatAdAccount',
                config: {
                    organizationId: 'c225e821-066b-4328-a8ad-6da44c5ab771'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'profile_properties.profile_id',
                identifier: 'snapchat_ad-profile_properties-profile_id',
                label: 'Public profile',
                metadataType: 'snapchatPublicProfile',
                config: {
                    organizationId: 'c225e821-066b-4328-a8ad-6da44c5ab771'
                },
                useValueObject: false
            },
            {
                type: 'select',
                model: 'status',
                label: 'Status',
                tooltip: 'Status of the ad',
                identifier: 'snapchat_ad-status',
                options: {
                    PAUSED: 'Paused',
                    ACTIVE: 'Active'
                }
            }
        ]
    }
];

export default settings;
