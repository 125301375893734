import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_SETTINGS } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Budget & Schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'delivery_constraint',
                identifier: 'snapchat_ad_squad-delivery_constraint',
                label: 'Budget type',
                tooltip: 'Type of the budget',
                options: {
                    DAILY_BUDGET: 'Daily budget',
                    LIFETIME_BUDGET: 'Lifetime budget'
                }
            },
            {
                type: 'number',
                tooltip: 'Daily budget micro',
                identifier: 'snapchat_ad_squad-budget',
                model: 'budget',
                label: 'Daily budget',
                condition: `additionalVars.brick.data.settings?.delivery_constraint === 'DAILY_BUDGET'`,
                startAdornment: '€'
            },
            {
                type: 'number',
                tooltip: 'Lifetime budget micro',
                model: 'budget',
                label: 'Lifetime budget',
                condition: `additionalVars.brick.data.settings?.delivery_constraint === 'LIFETIME_BUDGET'`,
                startAdornment: '€'
            },
            {
                type: 'date',
                identifier: 'snapchat_ad_squad-start_time',
                model: 'start_time',
                label: 'Start date'
            },
            {
                type: 'switch',
                model: 'has_end_time',
                identifier: 'snapchat_campaign-has_end_time',
                label: 'Set an end date (optional)',
                condition: `additionalVars.brick.data.settings?.delivery_constraint === 'DAILY_BUDGET'`
            },
            {
                type: 'date',
                model: 'end_time',
                identifier: 'snapchat_ad_squad-end_time',
                label: 'End date',
                condition: `additionalVars.brick.data.settings?.has_end_time === true || additionalVars.brick.data.settings?.delivery_constraint === 'LIFETIME_BUDGET'`
            }
        ]
    },
    {
        title: 'Locations',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'targeting.geos',
                identifier: 'snapchat_ad_squad-targeting-geos',
                label: 'Locations',
                metadataType: 'snapchatGeoLocation',
                config: {
                    countryCode: 'bg',
                    type: 'country'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Demographics',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'targeting.demographics.age_groups',
                identifier: 'snapchat_ad_squad-targeting-age_groups',
                label: 'Age group',
                metadataType: 'snapchatDemographic',
                config: {
                    type: 'age_group'
                },
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'targeting.demographics.gender',
                identifier: 'snapchat_ad_squad-targeting-gender',
                label: 'Genders',
                options: {
                    GENDER_UNLIMITED: 'All',
                    MALE: 'Male',
                    FEMALE: 'Female'
                }
            },
            {
                type: 'metadataInput',
                model: 'targeting.demographics.languages',
                identifier: 'snapchat_ad_squad-targeting-languages',
                label: 'Languages',
                metadataType: 'snapchatDemographic',
                config: {
                    type: 'languages'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Audiences',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'targeting.interests',
                identifier: 'snapchat_ad_squad-targeting-interests',
                label: 'Predefined Audiences',
                metadataType: 'snapchatInterest',
                config: {
                    type: 'scls'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Devices',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'targeting.devices.os_type',
                identifier: 'snapchat_ad_squad-targeting-os_type',
                label: 'Operating systems',
                options: {
                    ALL: 'All',
                    ANDROID: 'Android',
                    iOS: 'iOS'
                }
            },
            {
                type: 'metadataInput',
                model: 'targeting.devices.marketing_name',
                identifier: 'snapchat_ad_squad-targeting-marketing_name',
                label: 'Device Makes',
                metadataType: 'snapchatDevice',
                config: {
                    type: 'marketing_name'
                },
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'targeting.devices.connection_type',
                identifier: 'snapchat_ad_squad-targeting-connection_type',
                label: 'Connection type',
                options: {
                    ALL: 'All',
                    CELL: 'Cell',
                    WIFI: 'WiFi'
                }
            },
            {
                type: 'metadataInput',
                model: 'targeting.devices.carrier',
                identifier: 'snapchat_ad_squad-targeting-carrier',
                label: 'Carriers',
                metadataType: 'snapchatDevice',
                config: {
                    type: 'carrier'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Delivery',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'status',
                label: 'Status',
                identifier: 'snapchat_ad_squad-status',
                tooltip: 'Status',
                defaultValue: 'PAUSED',
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'snapchat_ad_squad-optimization_goal',
                label: 'Optimization goal',
                condition: `!additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective_v2_properties.objective_v2_type || additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective_v2_properties.objective_v2_type === ''`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example, with App installs, Snapchat delivers your ad to people who are more likely to install your app.",
                options: {
                    IMPRESSIONS: 'Optimize for Impressions, Cost per 1000 Impressions (CPM)',
                    SWIPES: 'Optimize for Swipe Ups',
                    APP_INSTALLS: 'Optimize for App Installs',
                    VIDEO_VIEWS: 'Optimize for 2-Second Video Views',
                    VIDEO_VIEWS_15_SEC: 'Optimize for 15-Second Video Views',
                    USES: 'Optimize for Filter Uses',
                    STORY_OPENS: 'Optimize for Story Opens',
                    PIXEL_PAGE_VIEW: 'Optimize for Web Page Views',
                    PIXEL_ADD_TO_CART: 'Optimize for Add to Cart (Web)',
                    LANDING_PAGE_VIEW: 'Optimize for Landing Page Views',
                    PIXEL_PURCHASE: 'Optimize for Purchases (Web)',
                    PIXEL_SIGNUP: 'Optimize for Sign Ups (Web)',
                    APP_ADD_TO_CART: 'Optimize for Add to Cart (App)',
                    APP_PURCHASE: 'Optimize for Purchases (App)',
                    APP_SIGNUP: 'Optimize for Sign Ups (App)'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'snapchat_ad_squad-optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective_v2_properties.objective_v2_type === 'AWARENESS_AND_ENGAGEMENT'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example, with App installs, Snapchat delivers your ad to people who are more likely to install your app.",
                options: {
                    IMPRESSIONS: 'Optimize for Impressions, Cost per 1000 Impressions (CPM)',
                    SWIPES: 'Optimize for Swipe Ups',
                    STORY_OPENS: 'Optimize for Story Opens',
                    USES: 'Optimize for Filter Uses',
                    VIDEO_VIEWS: 'Optimize for 2-Second Video Views',
                    VIDEO_VIEWS_15_SEC: 'Optimize for 15-Second Video Views'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'snapchat_ad_squad-optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective_v2_properties.objective_v2_type === 'APP_PROMOTION'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example, with App installs, Snapchat delivers your ad to people who are more likely to install your app.",
                options: {
                    IMPRESSIONS: 'Optimize for Impressions, Cost per 1000 Impressions (CPM)',
                    SWIPES: 'Optimize for Swipe Ups',
                    APP_INSTALLS: 'Optimize for App Installs',
                    APP_PURCHASE: 'Optimize for Purchases (App)',
                    APP_SIGNUP: 'Optimize for Sign Ups (App)',
                    APP_ADD_TO_CART: 'Optimize for Add to Cart (App)',
                    APP_LEVEL_COMPLETE: 'Optimize for Level Completion (App)',
                    APP_ACHIEVEMENT_UNLOCKED: 'Optimize for Achievement Unlocks (App)',
                    APP_AD_VIEW: 'Optimize for Ad Views (App)',
                    LANDING_PAGE_VIEW: 'Optimize for Landing Page Views',
                    APP_REENGAGE_PURCHASE: 'Optimize for Re-engaged Purchases (App)',
                    APP_REENGAGE_OPEN: 'Optimize for Re-engaged Opens (App)'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'snapchat_ad_squad-optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective_v2_properties.objective_v2_type === 'SALES'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example, with App installs, Snapchat delivers your ad to people who are more likely to install your app.",
                options: {
                    SWIPES: 'Optimize for Swipe Ups',
                    STORY_OPENS: 'Optimize for Story Opens',
                    PIXEL_PURCHASE: 'Optimize for Purchases (Web)',
                    PIXEL_SIGNUP: 'Optimize for Sign Ups (Web)',
                    PIXEL_ADD_TO_CART: 'Optimize for Add to Cart (Web)',
                    PIXEL_PAGE_VIEW: 'Optimize for Page Views (Web)',
                    LANDING_PAGE_VIEW: 'Optimize for Landing Page Views',
                    IMPRESSIONS: 'Optimize for Impressions, Cost per 1000 Impressions (CPM)',
                    APP_REENGAGE_PURCHASE: 'Optimize for Re-engaged Purchases (App)',
                    APP_REENGAGE_OPEN: 'Optimize for Re-engaged Opens (App)'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'snapchat_ad_squad-optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective_v2_properties.objective_v2_type === 'LEADS'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example, with App installs, Snapchat delivers your ad to people who are more likely to install your app.",
                options: {
                    SWIPES: 'Optimize for Swipe Ups',
                    STORY_OPENS: 'Optimize for Story Opens',
                    PIXEL_SIGNUP: 'Optimize for Sign Ups (Web)',
                    LANDING_PAGE_VIEW: 'Optimize for Landing Page Views',
                    LEAD_FORM_SUBMISSIONS: 'Optimize for Lead Form Submissions',
                    IMPRESSIONS: 'Optimize for Impressions, Cost per 1000 Impressions (CPM)'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'snapchat_ad_squad-optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective_v2_properties.objective_v2_type === 'TRAFFIC'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example, with App installs, Snapchat delivers your ad to people who are more likely to install your app.",
                options: {
                    SWIPES: 'Optimize for Swipe Ups',
                    PIXEL_PAGE_VIEW: 'Optimize for Page Views (Web)',
                    LANDING_PAGE_VIEW: 'Optimize for Landing Page Views',
                    APP_REENGAGE_PURCHASE: 'Optimize for Re-engaged Purchases (App)',
                    APP_REENGAGE_OPEN: 'Optimize for Re-engaged Opens (App)',
                    IMPRESSIONS: 'Optimize for Impressions, Cost per 1000 Impressions (CPM)'
                }
            },
            {
                type: 'select',
                model: 'bid_strategy',
                identifier: 'snapchat_ad_squad-bid_strategy',
                label: 'Bid strategy',
                tooltip:
                    'Choose bid strategy for this campaign to suit your specific business goals. Each strategy has tradeoffs and may be available for certain optimization_goals',
                options: {
                    AUTO_BID: 'Auto bid',
                    LOWEST_COST_WITH_MAX_BID: 'Lowest cost with max bid'
                    // TARGET_COST: 'Target cost'
                }
            },
            {
                type: 'number',
                tooltip: 'Bid price micro',
                model: 'bid_micro',
                identifier: 'snapchat_ad_squad-bid_micro',
                label: 'Bid price',
                startAdornment: '€',
                condition: `additionalVars.brick.data.settings?.bid_strategy !== 'AUTO_BID'`
            },
            {
                type: 'select',
                model: 'type',
                identifier: 'snapchat_ad_squad-type',
                label: 'Ad Squad Type',
                defaultValue: 'SNAP_ADS',
                readOnly: true,
                options: {
                    SNAP_ADS: 'Snap ads'
                    // LENS: 'Lens',
                    // FILTER: 'Filter'
                }
            },
            {
                type: 'select',
                model: 'pacing_type',
                label: 'Pacing type',
                identifier: 'snapchat_ad_squad-pacing_type',
                tooltip: 'Pacing type',
                options: {
                    STANDARD: 'Standard',
                    ACCELERATED: 'Accelerated'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                label: 'Billing event',
                identifier: 'snapchat_ad_squad-billing_event',
                tooltip: 'The billing event for this ad set',
                options: {
                    IMPRESSION: 'Impressions'
                }
            },
            {
                type: 'autocomplete',
                tooltip: 'Data derived from third party tags, including measurement reports',
                model: 'measurement_provider_names',
                identifier: 'snapchat_ad_squad-measurement_provider_names',
                label: 'Third party tagging',
                options: ['MOAT_SS', 'DOUBLEVERIFY']
            }
        ]
    },
    //TODO: TO be discussed
    // {
    //     title: 'Ad Set Details',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     opened: true,
    //     items: [
    //         {
    //             type: 'buttonGroup',
    //             model: 'forced_view_setting',
    //             label: 'Forced view setting',
    //             options: {
    //                 FULL_DURATION: 'Full duration',
    //                 SIX_SECONDS: 'Six seconds'
    //             }
    //         },

    //         {
    //             type: 'text',
    //             model: 'type',
    //             label: 'Ad squad type',
    //             disabled: true,
    //             useValueObject: false
    //         },

    //         {
    //             type: 'number',
    //             tooltip: 'Roas value micro',
    //             model: 'roas_value_micro',
    //             label: 'Bid price'
    //         },
    //         {
    //             type: 'text',
    //             model: 'reach_and_frequency_status**',
    //             label: 'Reach and frequency status',
    //             disabled: true,
    //             useValueObject: false
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Reach goal as specified in the Forecasting request',
    //             model: 'reach_goal**',
    //             label: 'Reach goal'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Reach goal as specified in the Forecasting request',
    //             model: 'impression_goal**',
    //             label: 'Impression goal'
    //         }
    //     ]
    // },
    {
        title: 'Placement options',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'switch',
                model: 'placement_v2.config',
                identifier: 'snapchat_ad_squad-placement_v2-config',
                label: 'Automatic placement',
                tooltip: 'Whether to allow comments on your ads on TikTok.'
            },
            {
                type: 'checkboxList',
                model: 'placement_v2.snapchat_positions',
                label: 'Positions',
                condition: `!additionalVars.brick.${MODEL_SETTINGS}.placement_v2.config`,
                identifier: 'snapchat_ad_squad-snapchat_positions',
                options: {
                    INTERSTITIAL_USER: 'Interstitial user',
                    INTERSTITIAL_CONTENT: 'Interstitial content',
                    INTERSTITIAL_SPOTLIGHT: 'Interstitial spotlight',
                    INSTREAM: 'In-stream',
                    PUBLIC_STORIES_INSTREAM: 'Public stories instream',
                    CHAT_FEED: 'Chat feed',
                    FEED: 'Feed'
                }
            },
            {
                type: 'select',
                model: 'brand_safety_config.inventory_option',
                label: 'Brand Safety Filter',
                tooltip: 'Choose where your ads are placed amongst Snapchat content.',
                identifier: 'snapchat_ad_squad-brand_safety_config-inventory_option',
                options: {
                    FULL_INVENTORY: 'Full inventory',
                    LIMITED_INVENTORY: 'Limited inventory'
                }
            }
        ]
    },
    {
        title: 'Delivery',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        condition: `additionalVars.snapchat_campaign?.${MODEL_SETTINGS}.objective_v2_properties?.objective_v2_type === 'AWARENESS_AND_ENGAGEMENT'`,
        opened: true,
        items: [
            {
                type: 'switch',
                model: 'frequency_enabled',
                identifier: 'snapchat_ad_squad-frequency_enabled',
                label: 'Set per user frequency cap'
            },
            {
                type: 'number',
                tooltip: '	Number of times an ad is shown to the user in the interval',
                identifier: 'snapchat_ad_squad-cap_and_exclusion_config-frequency_cap_config-frequency_cap_count',
                model: 'cap_and_exclusion_config.frequency_cap_config.frequency_cap_count',
                label: 'Frequency cap count',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.frequency_enabled`
            },
            {
                type: 'number',
                tooltip:
                    'Interval during which the frequency cap rule is applied. frequency_cap_count is reset at the end of the interval. (Max 30 days or 720 hours)',
                identifier: 'snapchat_ad_squad-cap_and_exclusion_config-frequency_cap_config-time_interval',
                model: 'cap_and_exclusion_config.frequency_cap_config.time_interval',
                label: 'Frequency time interval (days)',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.frequency_enabled`
            },
            {
                type: 'select',
                identifier: 'snapchat_ad_squad-cap_and_exclusion_config-frequency_cap_config-frequency_cap_type',
                model: 'cap_and_exclusion_config.frequency_cap_config.frequency_cap_type',
                label: 'Frequency cap type',
                readOnly: true,
                condition: `additionalVars.brick.${MODEL_SETTINGS}.frequency_enabled`,
                options: {
                    IMPRESSIONS: 'Impressions',
                    SWIPES: 'Swipes',
                    VIDEO_VIEWS_15_SEC: 'Video views 15 sec',
                    VIDEO_VIEWS: 'Video views',
                    STORY_OPENS: 'Story opens',
                    USES: 'Uses'
                }
            }
        ]
    }
];

export default settings;
