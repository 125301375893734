import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                fixedModel: true,
                maxLength: 150,
                useValueObject: false
            }
        ]
    },

    {
        title: 'Campaign setup',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'ad_account_id',
                identifier: 'snapchat_campaign-ad_account_id',
                label: 'Ad account',
                metadataType: 'snapchatAdAccount',
                config: {
                    organizationId: 'c225e821-066b-4328-a8ad-6da44c5ab771'
                },
                useValueObject: false
            },
            {
                type: 'select',
                model: 'objective_v2_properties.objective_v2_type',
                identifier: 'snapchat_campaign-objective',
                label: 'Objective',
                tooltip: 'The ultimate goal of your marketing campaign.',
                options: {
                    AWARENESS_AND_ENGAGEMENT: 'Awareness and engagement',
                    SALES: 'Sales',
                    TRAFFIC: 'Traffic',
                    APP_PROMOTION: 'App promotion',
                    LEADS: 'Leads'
                }
            },
            // {
            //     type: 'select',
            //     model: 'objective_v2_properties.promotion_type',
            //     identifier: 'snapchat_campaign-promotion_type',
            //     label: 'Promotion type',
            //     options: {
            //         PROMOTE_PLACES: 'Promote places',
            //         PROMOTE_SHOWS: 'Promote shows',
            //         APP_INSTALL: 'App install',
            //         APP_REENGAGEMENT: 'App reengagement'
            //     }
            // },

            {
                type: 'select',
                model: 'status',
                identifier: 'snapchat_campaign-status',
                label: 'Status',
                tooltip: 'Status',
                defaultValue: 'PAUSED',
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            },
            {
                type: 'date',
                model: 'start_time',
                identifier: 'snapchat_campaign-start_time',
                label: 'Start date'
            },
            {
                type: 'switch',
                model: 'has_end_time',
                identifier: 'snapchat_campaign-has_end_time',
                label: 'Set an end date (optional)'
            },
            {
                type: 'date',
                model: 'end_time',
                identifier: 'snapchat_campaign-end_time',
                label: 'End date',
                condition: `additionalVars.brick.data.settings?.has_end_time === true`
            },
            // {
            //     type: 'text',
            //     model: 'measurement_spec',
            //     label: 'Measurement specifications',
            //     placeholder: "e.g. '{'ios_app_id':'1234', 'android_app_url':'com.snapchat.android'}'",
            //     helperText: 'The apps to be tracked for this campaign',
            //     useValueObject: false
            // },
            // {
            //     type: 'buttonGroup',
            //     model: 'buy_model',
            //     label: 'Buy model',
            //     defaultValue: 'AUCTION',
            //     tooltip: 'The ultimate goal of your marketing campaign.',
            //     options: {
            //         AUCTION: 'Auction',
            //         RESERVED: 'Reserved'
            //     }
            // },
            {
                type: 'number',
                tooltip: 'Daily spend cap',
                identifier: 'snapchat_campaign-daily_budget_micro',
                model: 'daily_budget_micro',
                label: 'Daily spend cap',
                startAdornment: '€'
            },
            {
                type: 'number',
                tooltip: 'Lifetime spend cap',
                model: 'lifetime_spend_cap_micro',
                identifier: 'snapchat_campaign-lifetime_spend_cap_micro',
                label: 'Lifetime spend cap',
                startAdornment: '€'
            }
        ]
    },

    // {
    //     type: 'text',
    //     model: 'measurement_spec',
    //     label: 'Measurement specifications',
    //     placeholder: "e.g. '{'ios_app_id':'1234', 'android_app_url':'com.snapchat.android'}'",
    //     helperText: 'The apps to be tracked for this campaign',
    //     useValueObject: false
    // },
    // {
    //     type: 'buttonGroup',
    //     model: 'buy_model',
    //     label: 'Buy model',
    //     defaultValue: 'AUCTION',
    //     tooltip: 'The ultimate goal of your marketing campaign.',
    //     options: {
    //         AUCTION: 'Auction',
    //         RESERVED: 'Reserved'
    //     }
    // },

    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'dateRange',
                model: 'planning',
                label: 'Schedule date & time'
            }
        ]
    }
];

export default settings;
