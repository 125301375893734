/* eslint-disable no-useless-escape */
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import { ValidationObject } from 'components/bricks/types/validator.type';

// Ad setup data from the brick object
const brickAdSetup = 'brick.data?.adSetup?';

// Ad setup is of type 'single'
const isSingleAdSetup = `brick.data?.adSetup?.type === 'single'`;

// Ad setup is of type 'multi'
const isMultiAdSetup = `brick.data?.adSetup?.type === 'multi'`;

const campaignObjectiveTypeTraffic = `parent?.parent?.data?.settings.objective_v2_properties.objective_v2_type === 'TRAFFIC'`;

const validators: ValidationObject = {
    validators: [
        {
            condition: `!brick.data?.settings?.status || !brick?.data?.settings?.status.length`,
            message: 'Status is required',
            inputIdentifiers: ['snapchat_ad-status'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.title || !brick.title.length',
            message: 'Ad title is required',
            inputIdentifiers: ['title'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.ad_account_id || !brick?.data?.settings?.ad_account_id.length',
            message: 'Ad account id is required',
            inputIdentifiers: ['snapchat_ad-ad_account_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.profile_properties?.profile_id || !brick?.data?.settings?.profile_properties?.profile_id.length',
            message: 'Public profile id is required',
            inputIdentifiers: ['snapchat_ad-profile_properties-profile_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `!${brickAdSetup}`,
            message: 'There is no data in ad setup',
            inputIdentifiers: ['snapchat_ad-ad_setup'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0] && !${brickAdSetup}.items[0].creativeIds?.length`,
            message: 'There are no creatives',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0] && !${brickAdSetup}.items[0].creativeIds?.length`,
            message: 'There are no creatives in the first frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1] && !${brickAdSetup}.items[1].creativeIds?.length`,
            message: 'There are no creatives in the second frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-1'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2] && !${brickAdSetup}.items[2].creativeIds?.length`,
            message: 'There are no creatives in the third frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-2'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3] && !${brickAdSetup}.items[3].creativeIds?.length`,
            message: 'There are no creatives in the fourth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-3'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4] && !${brickAdSetup}.items[4].creativeIds?.length`,
            message: 'There are no creatives in the fifth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-4'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5] && !${brickAdSetup}.items[5].creativeIds?.length`,
            message: 'There are no creatives in the sixth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-5'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6] && !${brickAdSetup}.items[6].creativeIds?.length`,
            message: 'There are no creatives in the seventh frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-6'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7] && !${brickAdSetup}.items[7].creativeIds?.length`,
            message: 'There are no creatives in the eigth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-7'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8] && !${brickAdSetup}.items[8].creativeIds?.length`,
            message: 'There are no creatives in the ninth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-8'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9] && !${brickAdSetup}.items[9].creativeIds?.length`,
            message: 'There are no creatives in the tenth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-9'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `!${brickAdSetup}.brand_name || !${brickAdSetup}.brand_name.length`,
            message: 'Brand name is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-brand_name'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Brand name is required'
                }
            ]
        },
        {
            condition: `!${brickAdSetup}.headline || !${brickAdSetup}.headline.length`,
            message: 'Headline is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: `${brickAdSetup}.headline?.length > 34`,
            message: 'Headline should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.adType === 'STORY' && !${brickAdSetup}.preview_headline || !${brickAdSetup}.preview_headline.length`,
            message: 'Preview headline is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-preview_headline'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Preview headline is required'
                }
            ]
        },
        {
            condition: `${brickAdSetup}.items?.[0]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'call_to_action')}`,
            message: 'Call to action is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isSingleAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[0]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[0]?.creativeType && ${brickAdSetup}.items?.[0]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the first frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[1]?.creativeType && ${brickAdSetup}.items?.[1]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the second frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-1'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[2]?.creativeType && ${brickAdSetup}.items?.[2]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the third frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-2'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[3]?.creativeType && ${brickAdSetup}.items?.[3]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the fourth frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-3'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[4]?.creativeType && ${brickAdSetup}.items?.[4]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the fifth frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-4'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[5]?.creativeType && ${brickAdSetup}.items?.[5]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the sixth frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-5'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[6]?.creativeType &&  ${brickAdSetup}.items?.[6]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the seventh frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-6'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[7]?.creativeType && ${brickAdSetup}.items?.[7]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the eighth frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-7'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && ${brickAdSetup}.items?.[8]?.creativeType && ${brickAdSetup}.items?.[8]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the ninth frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-8'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${campaignObjectiveTypeTraffic} && $${brickAdSetup}.items?.[9]?.creativeType && {brickAdSetup}.items?.[9]?.creativeType !== 'WEB_VIEW'`,
            message: 'When the campaign objective is "Traffic", the Attachment type in the tenth frame needs to be "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-creativeType-9'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[0]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'web_view_properties?.url')}`,
            message: 'Website URL is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Website URL is required when Attachment type is "Web view"'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'call_to_action')}`,
            message: 'Call to action in the first frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'call_to_action')}`,
            message: 'Call to action in the second frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-1'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'call_to_action')}`,
            message: 'Call to action in the third frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-2'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'call_to_action')}`,
            message: 'Call to action in the fourth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-3'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'call_to_action')}`,
            message: 'Call to action in the fifth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-4'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'call_to_action')}`,
            message: 'Call to action in the sixth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-5'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'call_to_action')}`,
            message: 'Call to action in the seventh frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-6'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'call_to_action')}`,
            message: 'Call to action in the eight frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-7'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'call_to_action')}`,
            message: 'Call to action in the ninth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-8'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'call_to_action')}`,
            message: 'Call to action in the tenth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-call_to_action-9'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[0]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'web_view_properties?.url')}`,
            message: 'Website URL in the first frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[1]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'web_view_properties?.url')}`,
            message: 'Website URL in the second frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-1'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[2]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'web_view_properties?.url')}`,
            message: 'Website URL in the third frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-2'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[3]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'web_view_properties?.url')}`,
            message: 'Website URL in the fourth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-3'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[4]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'web_view_properties?.url')}`,
            message: 'Website URL in the fifth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-4'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[5]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'web_view_properties?.url')}`,
            message: 'Website URL in the sixth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-5'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[6]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'web_view_properties?.url')}`,
            message: 'Website URL in the seventh frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-6'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[7]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'web_view_properties?.url')}`,
            message: 'Website URL in the eight frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-7'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[8]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'web_view_properties?.url')}`,
            message: 'Website URL in the ninth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-8'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.items?.[9]?.creativeType === 'WEB_VIEW' && ${ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'web_view_properties?.url')}`,
            message: 'Website URL in the tenth frame is required when Attachment type is "Web view"',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-9'],
            severity: 'error',
            tabKey: 'adSetup'
        },

        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.web_view_properties?.url && !${brickAdSetup}.items[0].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.web_view_properties?.url && !${brickAdSetup}.items[0].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in first frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.web_view_properties?.url && !${brickAdSetup}.items[1].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in second frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.web_view_properties?.url && !${brickAdSetup}.items[2].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in third frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.web_view_properties?.url && !${brickAdSetup}.items[3].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in fourth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.web_view_properties?.url && !${brickAdSetup}.items[4].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in fifth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.web_view_properties?.url && !${brickAdSetup}.items[5].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in sixth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.web_view_properties?.url && !${brickAdSetup}.items[6].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in seventh frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.web_view_properties?.url && !${brickAdSetup}.items[7].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in eigth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.web_view_properties?.url && !${brickAdSetup}.items[8].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in ninth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.web_view_properties?.url && !${brickAdSetup}.items[9].web_view_properties?.url?.${ValidateHelpers.getRegexMatch('httpsUrl')}`,
            message: 'Website URL in tenth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-web_view_properties.url-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/ }],
                    severity: 'error',
                    message: 'Website URL is not a valid URL'
                }
            ]
        }
    ]
};

export default validators;
