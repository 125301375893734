const defaultData = {
    data: {
        adSetup: {
            type: 'single',
            adType: 'SINGLE',
            items: [
                {
                    creativeType: 'SNAP_AD'
                }
            ]
        },
        settings: {
            status: 'PAUSED'
        }
    }
};

export default defaultData;
