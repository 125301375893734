import moment from 'moment';

const start_time = moment().format('YYYY-MM-DD HH:mm:ss');
const end_time = moment().add(5, 'd').format('YYYY-MM-DD HH:mm:ss');

const defaultData = {
    data: {
        settings: {
            objective_v2_properties: { objective_v2_type: 'AWARENESS_AND_ENGAGEMENT' },
            status: 'PAUSED',
            start_time,
            end_time
        }
    }
};

export default defaultData;
