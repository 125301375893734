import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!brick.title || !brick.title.length',
            message: 'Campaign title is required',
            inputIdentifiers: ['title'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.ad_account_id || !brick?.data?.settings?.ad_account_id.length',
            message: 'Ad account id is required',
            inputIdentifiers: ['snapchat_campaign-ad_account_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.start_time',
            message: 'Campaign start time is required',
            inputIdentifiers: ['snapchat_campaign-start_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `
                brick.data?.settings?.start_time &&
                new Date(new Date(brick.data.settings.start_time).setHours(0, 0, 0, 0)) <
                new Date(new Date().setHours(0, 0, 0, 0))
            `,
            message: 'Start date cannot be in the past',
            inputIdentifiers: ['snapchat_campaign-start_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `
                brick.data?.settings?.end_time &&
                new Date(new Date(brick.data.settings.end_time).setHours(0, 0, 0, 0)) <
                new Date(new Date().setHours(0, 0, 0, 0))
            `,
            message: 'Start date cannot be in the past',
            inputIdentifiers: ['snapchat_campaign-end_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data.settings.has_end_time === true && !brick.data.settings.end_time',
            message: 'End date is required when "Set end date" option is enabled',
            inputIdentifiers: ['snapchat_campaign-end_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.status || !brick?.data?.settings?.status.length',
            message: 'Status is required',
            inputIdentifiers: ['snapchat_campaign-status'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "!brick.data.settings.objective_v2_properties.objective_v2_type || brick.data.settings.objective_v2_properties.objective_v2_type === '' ",
            message: 'Campaign objective is required',
            inputIdentifiers: ['snapchat_campaign-objective'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data.settings.daily_budget_micro && brick.data.settings.daily_budget_micro <= 20',
            message: 'Daily spend cap should be greater than 20 or not set',
            inputIdentifiers: ['snapchat_campaign-daily_budget_micro'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data.settings.lifetime_spend_cap_micro && brick.data.settings.lifetime_spend_cap_micro <= 20',
            message: 'Lifetime spend cap should be greater than 20 or not set',
            inputIdentifiers: ['snapchat_campaign-lifetime_spend_cap_micro'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
