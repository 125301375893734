import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import CreativeSelector from 'components/input/CreativeSelector';
import { withResources } from 'components/data/Resources';
import { FieldSetCopy } from 'components/input/Copy';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import Translation from 'components/data/Translation';
import CarouselTabs from 'components/bricks/components/shared/components/ad-setup/components/carousel-tabs';
import AdSetupInputWrapper from 'components/bricks/components/shared/components/ad-setup/components/ad-setup-input-wrapper';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Divider from 'components/ui-components-v2/Divider';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';

import cloneDeep from 'helpers/cloneDeep';
import { AdSetupFrameType } from 'components/creatives-v2/components/social-previews-v2/social-preview.type';
import { AdSetup } from 'components/bricks/components/shared/components/ad-setup/types/AdSetup.type';
import { FieldSetSwitch } from 'components/input/Switch';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import useBrick from 'components/bricks/hooks/useBrick';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { MODEL_CREATIVES } from 'components/bricks/constants';
import { FieldSetAlertBox } from 'components/input/AlertBox';
import brickSetup from './setup';

import './styles/ad-setup.scss';

interface Props {
    handleSelectCreatives: (creatives) => void;
    snapchat_cta: {
        [key: string]: string;
    };
    handleChangeFrameType: (AdSetupFrameType: string) => void;
}

const MultiFrame: React.FC<Props> = ({ handleSelectCreatives, snapchat_cta, handleChangeFrameType }) => {
    const { brick } = useBrick();
    const { adSetup, updateAdSetupProperty, selectedFrame, creatives, updateAdSetupFull } = useAdSetupContext();
    const snapchatAdSetup: any = adSetup; // TODO Temporary because the adsetup is typed for meta and not in general

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    const inputValidators = useMemo(() => {
        return {
            brand_name: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-brand_name`),
            headline: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-headline`),
            preview_headline: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-preview_headline`),
            url: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-web_view_properties.url-${selectedFrame}`)
        };
    }, [selectedFrame]);

    const previewCreative: CreativeV2 | undefined = useMemo(() => {
        return brick?.data?.creatives?.find((creative: CreativeV2) => creative.id === adSetup?.previewCreativeId);
    }, [adSetup?.previewCreativeId]);

    const handleChangeAdType = (newValue: string) => {
        const newAdSetup: AdSetup = cloneDeep(adSetup);
        newAdSetup.adType = newValue;

        let frameType: AdSetupFrameType = 'single';

        if (newValue === 'STORY') {
            frameType = 'multi';
        }

        newAdSetup.type = frameType;
        handleChangeFrameType(frameType);
        updateAdSetupFull(newAdSetup);
    };

    const handleSelectPreviewMedia = (creatives: CreativeV2[]) => {
        const creative = creatives?.[0];
        if (!brick) return;

        const brickCopy = cloneDeep(brick);
        let brickCreatives: CreativeV2[] = brickCopy?.data?.creatives || [];

        if (!creative) {
            updateAdSetupProperty('previewCreativeId', undefined, false);
            brickCreatives = brickCreatives.filter((brickCreative) => brickCreative.id !== adSetup?.previewCreativeId);
            BricksComponentStoreHelper.setBrickModel(brickCopy.id, MODEL_CREATIVES, brickCreatives);

            return;
        }

        brickCreatives.push(creative);
        BricksComponentStoreHelper.setBrickModel(brickCopy.id, MODEL_CREATIVES, brickCreatives);
        updateAdSetupProperty('previewCreativeId', creative.id, false);
    };

    return (
        <>
            <Accordion
                square
                title={Translation.get('adSetup.main', 'bricks')}
                classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details', summaryRoot: 'ad-setup__accordion__summary__root' }}>
                <div className="ad-setup__accordion__inputs">
                    <AdSetupInputWrapper>
                        <FieldSetSelectInput
                            label={Translation.get('labels.type', 'common')}
                            useValueObject={false}
                            onMutation={(newValue) => handleChangeAdType(newValue)}
                            value={adSetup?.adType}
                            options={{
                                SINGLE: 'Single image or video',
                                STORY: 'Story'
                            }}
                        />
                    </AdSetupInputWrapper>

                    <AdSetupInputWrapper>
                        <FieldSetCopy
                            label={Translation.get('adSetup.snapchatAd.brandName', 'bricks')}
                            useValueObject={false}
                            onMutation={(newValue) => updateAdSetupProperty('brand_name', newValue, false)}
                            value={snapchatAdSetup?.brand_name}
                            validators={inputValidators.brand_name}
                        />
                    </AdSetupInputWrapper>
                    <AdSetupInputWrapper>
                        <FieldSetCopy
                            label={Translation.get('adSetup.snapchatAd.headline', 'bricks')}
                            onMutation={(newValue) => updateAdSetupProperty('headline', newValue, false)}
                            value={snapchatAdSetup?.headline}
                            useValueObject={false}
                            validators={inputValidators.headline}
                        />
                    </AdSetupInputWrapper>
                    <AdSetupInputWrapper>
                        <FieldSetSwitch
                            label={'Shareable'}
                            useValueObject={false}
                            onMutation={(newValue) => updateAdSetupProperty('shareable', newValue, false)}
                            value={snapchatAdSetup?.shareable}
                        />
                    </AdSetupInputWrapper>
                    <span className="ad-setup__accordion__inputs__copy">{Translation.get('adSetup.snapchatAd.previewImage', 'bricks')}</span>
                    <AdSetupInputWrapper>
                        <FieldSetAlertBox
                            fullWidth
                            message={'The file should be max 2MB, file should be PNG, and the aspect ratio should be 3:5'}
                            alertType={'warning'}
                        />
                    </AdSetupInputWrapper>
                    <CreativeSelector
                        onMutation={(creatives) => handleSelectPreviewMedia(creatives)}
                        multiple={false}
                        canEditCreative={canEditCreative}
                        value={previewCreative ? [previewCreative] : []}
                        selectors={['upload']}
                        templateTypes={templateTypes}
                        fileType={'image'}
                    />
                    <AdSetupInputWrapper>
                        <FieldSetCopy
                            label={Translation.get('adSetup.snapchatAd.previewHeadline', 'bricks')}
                            useValueObject={false}
                            onMutation={(newValue) => updateAdSetupProperty('preview_headline', newValue, false)}
                            value={snapchatAdSetup?.preview_headline}
                            validators={inputValidators.preview_headline}
                        />
                    </AdSetupInputWrapper>
                </div>
            </Accordion>

            <Accordion square={true} title="Multi frame" classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details--multi-frame' }}>
                <CarouselTabs identifier="meta_ad-ad_setup-carousel_tabs">
                    <div className="ad-setup__accordion__inputs">
                        <span className="ad-setup__accordion__inputs__copy">{Translation.get('adSetup.media', 'bricks')}</span>
                        <CreativeSelector
                            onMutation={(creatives) => handleSelectCreatives(creatives)}
                            multiple={multiple}
                            canEditCreative={canEditCreative}
                            value={creatives}
                            selectors={selectors}
                            templateTypes={templateTypes}
                        />

                        <Divider />

                        <AdSetupInputWrapper>
                            <FieldSetSelectInput
                                label={Translation.get('adSetup.snapchatAd.attachmentType', 'bricks')}
                                useValueObject={false}
                                onMutation={(newValue) => updateAdSetupProperty('creativeType', newValue, true)}
                                value={snapchatAdSetup?.items?.[selectedFrame].creativeType}
                                defaultValue="SNAP_AD"
                                options={{
                                    SNAP_AD: 'None',
                                    WEB_VIEW: 'Web view'
                                }}
                            />
                        </AdSetupInputWrapper>
                        <Divider />
                        {snapchatAdSetup?.items?.[selectedFrame].creativeType !== 'SNAP_AD' && (
                            <AdSetupInputWrapper>
                                <FieldSetCopy
                                    label={Translation.get('adSetup.snapchatAd.websiteUrl', 'bricks')}
                                    useValueObject={false}
                                    onMutation={(newValue) => updateAdSetupProperty('web_view_properties.url', newValue, true)}
                                    value={adSetup.items?.[selectedFrame]?.web_view_properties?.url}
                                    validators={inputValidators.url}
                                />
                            </AdSetupInputWrapper>
                        )}
                        {snapchatAdSetup?.items?.[selectedFrame].creativeType !== 'SNAP_AD' && (
                            <AdSetupInputWrapper>
                                <FieldSetSelectInput
                                    label={Translation.get('adSetup.snapchatAd.callToAction', 'bricks')}
                                    useValueObject={false}
                                    value={adSetup.items?.[selectedFrame]?.call_to_action}
                                    onMutation={(newValue) => updateAdSetupProperty('call_to_action', newValue, true)}
                                    options={snapchat_cta}
                                />
                            </AdSetupInputWrapper>
                        )}
                    </div>
                </CarouselTabs>
            </Accordion>
        </>
    );
};

export default withResources(MultiFrame, ['snapchat_cta']);
