import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Translation from 'components/data/Translation';
import { FieldSetCopy } from 'components/input/Copy';
import CreativeSelector from 'components/input/CreativeSelector';
import Divider from 'components/ui-components-v2/Divider';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import AdSetupInputWrapper from 'components/bricks/components/shared/components/ad-setup/components/ad-setup-input-wrapper';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import { withResources } from 'components/data/Resources';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import { AdSetupFrameType } from 'components/creatives-v2/components/social-previews-v2/social-preview.type';
import cloneDeep from 'helpers/cloneDeep';
import { AdSetup } from 'components/bricks/components/shared/components/ad-setup/types/AdSetup.type';
import { FieldSetSwitch } from 'components/input/Switch';
import brickSetup from './setup';

import './styles/ad-setup.scss';

interface Props {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
    snapchat_cta: {
        [key: string]: string;
    };
    handleChangeFrameType: (AdSetupFrameType: string) => void;
}

const SingleFrame: React.FC<Props> = ({ handleSelectCreatives, snapchat_cta, handleChangeFrameType }) => {
    const { adSetup, creatives, updateAdSetupProperty, updateAdSetupFull } = useAdSetupContext();
    const snapchatAdSetup: any = adSetup; // TODO Temporary because the adsetup is typed for meta and not in general

    const inputValidators = useMemo(() => {
        return {
            brand_name: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-brand_name`),
            headline: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-headline`),
            url: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-web_view_properties.url-0`)
        };
    }, []);

    const handleChangeAdType = (newValue: string) => {
        const newAdSetup: AdSetup = cloneDeep(adSetup);
        newAdSetup.adType = newValue;

        let frameType: AdSetupFrameType = 'single';

        if (newValue === 'STORY') {
            frameType = 'multi';
        }

        newAdSetup.type = frameType;
        handleChangeFrameType(frameType);
        updateAdSetupFull(newAdSetup);
    };

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <Accordion
            square
            title={Translation.get('adSetup.main', 'bricks')}
            classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details', summaryRoot: 'ad-setup__accordion__summary__root' }}>
            <div className="ad-setup__accordion__inputs">
                <AdSetupInputWrapper>
                    <FieldSetSelectInput
                        label={Translation.get('labels.type', 'common')}
                        useValueObject={false}
                        onMutation={(newValue) => handleChangeAdType(newValue)}
                        value={adSetup?.adType}
                        options={{
                            SINGLE: 'Single image or video',
                            STORY: 'Story'
                        }}
                    />
                </AdSetupInputWrapper>

                <Divider />
                <span className="ad-setup__accordion__inputs__copy">{Translation.get('adSetup.media', 'bricks')}</span>
                <CreativeSelector
                    onMutation={(creatives) => handleSelectCreatives(creatives)}
                    multiple={multiple}
                    canEditCreative={canEditCreative}
                    value={creatives}
                    selectors={selectors}
                    templateTypes={templateTypes}
                />
                <Divider />

                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.snapchatAd.brandName', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('brand_name', newValue, false)}
                        value={snapchatAdSetup?.brand_name}
                        validators={inputValidators.brand_name}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.snapchatAd.headline', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('headline', newValue, false)}
                        value={snapchatAdSetup?.headline}
                        validators={inputValidators.headline}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetSwitch
                        label={'Shareable'}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('shareable', newValue, false)}
                        value={snapchatAdSetup?.shareable}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetSelectInput
                        label={Translation.get('adSetup.snapchatAd.attachmentType', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('creativeType', newValue, true)}
                        value={snapchatAdSetup?.items?.[0].creativeType}
                        defaultValue="SNAP_AD"
                        options={{
                            SNAP_AD: 'None',
                            WEB_VIEW: 'Web view'
                        }}
                    />
                </AdSetupInputWrapper>
                {snapchatAdSetup?.items?.[0].creativeType !== 'SNAP_AD' && (
                    <AdSetupInputWrapper>
                        <FieldSetCopy
                            label={Translation.get('adSetup.snapchatAd.websiteUrl', 'bricks')}
                            useValueObject={false}
                            onMutation={(newValue) => updateAdSetupProperty('web_view_properties.url', newValue, true)}
                            value={adSetup.items?.[0]?.web_view_properties?.url}
                            validators={inputValidators.url}
                        />
                    </AdSetupInputWrapper>
                )}
                {snapchatAdSetup?.items?.[0].creativeType !== 'SNAP_AD' && (
                    <AdSetupInputWrapper>
                        <FieldSetSelectInput
                            label={Translation.get('adSetup.snapchatAd.callToAction', 'bricks')}
                            useValueObject={false}
                            value={adSetup.items?.[0]?.call_to_action}
                            onMutation={(newValue) => updateAdSetupProperty('call_to_action', newValue, true)}
                            options={snapchat_cta}
                        />
                    </AdSetupInputWrapper>
                )}
            </div>
        </Accordion>
    );
};

export default withResources(SingleFrame, ['snapchat_cta']);
