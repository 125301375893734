import moment from 'moment';
import GeneralHelpers from 'components/bricks/helpers/general.helpers';
import placementsSection from '../data/placements';

const start_time = moment().format('YYYY-MM-DD HH:mm:ss');
const end_time = moment().add(5, 'd').format('YYYY-MM-DD HH:mm:ss');

const defaultData = {
    data: {
        settings: {
            targeting: {
                placements: GeneralHelpers.extractPlacementKeys(placementsSection)
            },
            pacing_type: 'STANDARD',
            start_time,
            end_time,
            billing_event: 'IMPRESSION',
            type: 'SNAP_ADS',
            bid_strategy: 'AUTO_BID',
            placement_v2: {
                config: true,
                platforms: ['SNAPCHAT']
            },
            delivery_constraint: 'DAILY_BUDGET',
            frequency_enabled: false,
            cap_and_exclusion_config: { frequency_cap_config: { frequency_cap_count: '1', time_interval: '7', frequency_cap_type: 'IMPRESSIONS' } },
            brand_safety_config: { inventory_option: 'FULL_INVENTORY' }
        }
    }
};

export default defaultData;
